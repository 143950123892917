import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import './index.scss';
import App from './App'

import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <App />,
  document.getElementById("root")
);

reportWebVitals();
