import React, { Suspense, lazy } from 'react';
import Preloader from './components/Preloader';
import 'sweetalert2/src/sweetalert2.scss'
const Main = lazy(() => import('./components/Main'))

function App() {

  return (
    <Suspense fallback={<Preloader />}>
      <Main />
    </Suspense>
  )
}

export default App;
